<template>
    <div>
        <!-- <TopBar/>
        <div id="linkk">
            <router-view></router-view>
        </div>
        <FooterBar/> -->
        <router-view></router-view>
    </div>
</template>

<script>
    // import TopBar from './components/TopBar.vue'
    // import BlogsNav from './components/BlogsNav.vue';
    // import FooterBar from './components/FooterBar.vue';
    export default {
        name: 'App',
        // components:{TopBar,FooterBar}
    }
</script>

