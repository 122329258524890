<template>
    <div class="container">
        <div class="join-info">
            <h2>加入我们？</h2>
            <br>
            <p>发送一封邮件到959994590@qq.com，其中写明您提供的网站的网址以及介绍。</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .container{
        margin: 180px auto;
        height: 200px;
        width: 900px;
        background-color: rgb(183, 249, 207);
        border-radius: 10px;
        padding: 20px;
    }
    .join-info{
        padding: 10px;
        margin: 0 auto;
        /* background-color: rgb(193, 244, 106); */
        text-align: center;
    }
    h2{
        font-size: 24px;
    }
    p{
        font-size: 18px;
    }
</style>